.App {
  text-align: center;
  padding-bottom: 50px; /* Ensure there's space at the bottom */
}

.Blog {
  margin-top: 50px;
}

body {
  /* background-image: url('./assets/background.png'); */
  background-size: cover;
  background-position: center;
  margin: 0;
  font-family: 'Roboto', 'Arial', 'sans-serif';
}

.overlay {
  background-color: rgba(255, 255, 255, 0.8); /* Light overlay to make text readable */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

